import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFound = () => (
  <Layout className="page-404">
    <SEO title="404: Not found" />
    <div className="container">
	    <h1 className="title">No encontrado</h1>
	    <p>Página no encontrada :(</p>
	    <Link to="/">&lsaquo; Volver al inicio</Link>
    </div>
  </Layout>  
)

export default NotFound
